@import url('https://fonts.googleapis.com/css2?family=Anton&family=Babylonica&family=Bodoni+Moda:opsz,wght@6..96,400;6..96,500;6..96,700&family=Calligraffitti&family=Cinzel&family=EB+Garamond:ital,wght@0,400;0,600;1,400&family=Fauna+One&family=Henny+Penny&family=Lato:wght@100;300;400&family=League+Spartan:wght@200;300;400;500&family=Libre+Baskerville:wght@400;700&family=Montserrat:wght@100;200;300;400&family=Nunito:wght@200;400&family=Poppins:wght@100;200;300&display=swap');

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
 
  --clr-primary-1: #28AE93;
  --clr-primary-2: #F2E9E5;
  --clr-primary-3: #F7D341;
  --clr-primary-4: #F8F8F8;
  --clr-primary-8: #CBF2B7;
  --clr-primary-hover:#C3E345;
  --clr-primary-10: #F9F0DD ;
    --clr-primary-11: #4A320D;
    --clr-primary:12:#BBDC92;
  --clr-body: #12121C;
  --clr-white: #fff;
  
  --clr-black: #222;
    --primary-font1: 'Lato', sans-serif;
  --primary-font2: 'Merriweather', serif;
  --primary-font3: 'Montserrat' sans-serif;
  --primary-font4: 'Oswald', sans-serif;
  --primary-font5: 'Playfair Display', serif;
  --primary-font6: 'Raleway', sans-serif;
  --primary-font7: 'Rubik Microbe', cursive;
  --primary-font8: 'Rubik Moonrocks', cursive;
  --primary-font9: 'Anton', sans-serif;
  --primary-font10: 'Babylonica', cursive;
  --primary-font11: 'Bodoni Moda', serif;
  --primary-font12:  'Calligraffitti', cursive;
  --primary-font13: 'EB Garamond', serif;
  --primary-font14:'Henny Penny', cursive;
  --primary-font15:'League Spartan', sans-serif;
  --primary-font16:'Libre Baskerville', serif;
  --primary-font17:'Nunito', sans-serif;
  --primary-font18:'Poppins', sans-serif;
  --primary-font19: 'Cinzel', serif;
  --primary-font20: 'Fauna One', serif;
  --transition: all 0.3s linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1200px;
  --fixed-width: 620px;
  
}



body {
  background: var(--clr-white);
  color: var(--clr-black);
  transition: var(--darkModeTransition);
  font-family: var(--primary-font5);
  line-height: 1.5;
  font-size: 0.875rem;
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}
h1,
h2,
h3,
h4 {
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}
h1 {
  font-size: 2.5rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.25rem;
}
h4 {
  font-size: 0.875rem;
}
p {
  margin-bottom: 1.25rem;
}
@media screen and (min-width: 800px) {
  h1 {
    font-size: 3rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1rem;
  }
  body {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}
/*  global classes */
/* Button Styles */
.btn {
  background: var(--clr-black); 
  color:var(--clr-white); 
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Light shadow */
  transition: all 0.3s ease;
  cursor: pointer;
  letter-spacing: 1px;
}


.btn:hover,
.btn:focus {
  background: #333; 
  color: #e0e0e0; 
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.3);
  
}

/* Active State */

/* section */
.section-center {
  width: 90vw;
  margin: 5rem auto;
  max-width: var(--max-width);

}
.nav-center {
  width: 90vw;
  margin: 0 auto;
  max-width: var(--max-width);

}

.title h3{
  text-transform:uppercase;
  font-size: 1.5rem;
  font-weight: 800;
  color:var(--clr-black);
  text-align: center;
}
.title p{
  font-size: 1.5rem;
}
.underline{
  width: 10rem;
  height: 0.2rem;
  background: var(--clr-black);
  margin: 0 auto;
}
/* animates */
.outer{
  position:relative;
  
}
.animates{
  font-size:1rem;
  color: var(--clr-black);
  
}
.inner{
  position:absolute;
  border:0px solid var(--clr-white);
  height:50px;
  line-height:50px;
  font-size:1.5rem;
  text-transform:uppercase;
  overflow:hidden;
  display:inline-block;
  left: 0;
  
}
.inner span{
  animation:animate 20s ease infinite;
  position:relative;
  color:var(--clr-primary-1);
}
@keyframes animate{
  0%,100%{
    top:0;
  }
  20%{
    top:0px;
  }  
  25%{
    top:-50px;
  }
  45%{
    top:-50px;
  }
  50%{
    top:-100px;
  }
  70%{
    top:-100px;
  }
  75%{
    top:-150px;
  }
  95%{
    top:-150px;
  }
}

/* TopNav */
.top-nav{
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: var(--clr-primary-1);
  color: var(--clr-white);
  z-index: 999;
 
}

.top-nav h5{
  font-size: 1.2rem;
}
.top-nav a{
  color: var(--clr-white);
  margin-left: 0.5rem;
  cursor: pointer;
}
/* Navbar */
.no-wrap {
  white-space: nowrap;
}
.navbar {
  background-color: var(--clr-white);
  padding: 1rem;
  color: var(--clr-black);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed; 
  top:110px; 
  width: 100%; 
  z-index: 998; 
  box-shadow: var(--light-shadow);
}

.navbar-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

/* Logo Styling */
.logo {
  width: 150px; /* Adjust width according to your design */
  height: auto; /* Maintain aspect ratio */
  display: block; /* Remove any inline behavior */
  margin: 0 auto; /* Center the logo horizontally */
  transition: transform 0.3s ease-in-out; /* Add transition effect for hover */
}

/* Add hover effect for the logo */
.logo:hover {
  transform: scale(1.1); /* Slightly enlarge the logo on hover */
}

/* If the logo is inside a header or navbar, you can center it */


/* Optional: Logo on smaller screens */
@media (max-width: 768px) {
  .logo {
    width: 120px; /* Adjust size on mobile for responsiveness */
  }
}

.mobile-menu-icon button {
  color: var(--clr-primary-1);
  font-size: 1.5rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.navbar-menu {
  display: flex;
  list-style: none;
}

.navbar-item {
  position: relative;
}

.navbar-link {
  color: var(--clr-black);
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-weight: bold;
  font-size: 1.2rem;
  transition: var(--transition);
  cursor: pointer;
}

.navbar-link:hover {
  color: var(--clr-primary-1);
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: var(--clr-primary-10);
  padding: 0.5rem;
  list-style: none;
  z-index: 1000; 
}

.navbar-item:hover .dropdown-menu,
.dropdown-menu:hover {
  display: block; 
}

.dropdown-item {
  margin: 0.5rem 0;
}

.dropdown-link {
  color: var(--clr-primary-1);
  text-decoration: none;
  display: block;
  padding: 0.5rem;
}

.dropdown-link:hover {
  color: var(--clr-black);
}


.mobile-menu-icon {
  display: none;
  font-size: 1.5rem;
  color: var(--clr-black);
  cursor: pointer;
}

.navbar-menu.active {
  display: block;
  position: absolute;
  top: 60px; 
  right: 0;
  background-color: var(--clr-white);
  height: 100vh;
}


.back-button {
  display: none; 
  background-color: transparent;
  border: none;
  color: var(--clr-primary-1);
  font-size: 1rem;
  cursor: pointer;
  margin: 0.5rem 0;
}
@media (max-width: 768px) {
  .top-nav {
    flex-direction: column; 
    padding: 1rem; 
  }

  .top-nav h5 {
    font-size: 1rem; 
  }
}
@media (max-width: 768px) {
  
  .navbar-menu {
    display: none;
    flex-direction: column;
    width: 100%;
  }

  .navbar-menu.active {
    display: flex; 
  }

  .dropdown-menu {
    display: flex; 
    flex-direction: column; 
  }

  .navbar-item {
    width: 100%;
    text-align: center;
    cursor: pointer;
  }

  .mobile-menu-icon {
    display: block; 
  }

  .back-button {
    display: block; 
  }
}
@media (min-width:992px) {
  .navbar{
     top:20px; 
  }
}
/* Header Slider */
.hero-slider {
  position: relative;
  width: 100%;
  height: 500px;
  overflow: hidden;
}

.slide {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.8s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.slide.active {
  opacity: 1;
}

.slide-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.slide-content {
  position: relative;
  z-index: 2;
  max-width: 800px;
  color: var(--clr-white);
  padding: 20px;
}

.slide-title {
  font-size: 2rem;
  font-family: var(--primary-font4);
  text-transform: uppercase;
  margin-bottom: 10px;
  letter-spacing: var(--spacing);
}

.slide-description {
  font-size: 1.2rem;
  font-family: var(--primary-font18);
  margin-bottom: 20px;
}

.slide-button {
  display: inline-block;
  padding: 12px 20px;
  background-color: var(--clr-primary-3);
  color: var(--clr-black);
  text-decoration: none;
  font-family: var(--primary-font6);
  font-weight: bold;
  border-radius: var(--radius);
  transition: var(--transition);
}

.slide-button:hover {
  background-color: var(--clr-primary-hover);
  color: var(--clr-white);
}

/* Prev & Next Buttons */
.prev-button,
.next-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: var(--clr-white);
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 1.5rem;
  transition: var(--transition);
  z-index: 5;
}

.prev-button {
  left: 20px;
}

.next-button {
  right: 20px;
}

.prev-button:hover,
.next-button:hover {
  background: var(--clr-primary-3);
  color: var(--clr-black);
}
/* FirstBanner */
.banner-slider {
  width: 90%;
  max-width: 600px;
  margin: 20px auto;
  text-align: center;
}

.main-image img {
  width: 100%;
  max-width: 600px;
  height: 400px;
  object-fit: cover;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
}

.thumbnail-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 15px;
}

.thumbnail-container img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.thumbnail-container img:hover,
.thumbnail-container img.active {
  transform: scale(1.1);
  border: 2px solid #28AE93;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .banner-slider {
    width: 100%;
    padding: 10px;
  }

  .thumbnail-container {
    gap: 5px;
  }

  .thumbnail-container img {
    width: 50px;
    height: 50px;
  }
}

.menu-progress {
  width: 100%;
  max-width: 600px;
  margin: 20px auto;
  text-align: center;
  font-family: Arial, sans-serif;
}

.progress-bar {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 20px;
  border-bottom: 3px solid #ddd;
}

.menu-item {
  flex: 1;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  color: #555;
  position: relative;
  transition: color 0.3s ease;
}

.menu-item.active {
  color: #28AE93;
}

.menu-item::after {
  content: "";
  width: 0%;
  height: 3px;
  background-color: #28AE93;
  position: absolute;
  bottom: -3px;
  left: 50%;
  transform: translateX(-50%);
  transition: width 0.3s ease-in-out;
}

.menu-item.active::after {
  width: 100%;
}

.description {
  padding: 15px;
  font-size: 18px;
  color: #333;
  background: #f9f9f9;
  border-radius: 8px;
}
@media (min-width:768px) {
  .first-banner-container{
    display: flex;
    justify-content: space-between;
    gap: 5rem;
  }
}
/* BannerNumber */
.banner-number {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
  
}

.number-item {
  background: #f4f4f4;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  transition: all 0.3s ease;
}

.number-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.number-circle {
  background-color: #28AE93;
  color: white;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  margin-bottom: 15px;
}

.content h2 {
  font-size: 20px;
  color: #333;
  margin-bottom: 10px;
}

.content p {
  font-size: 14px;
  color: #666;
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .banner-number {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .banner-number {
    grid-template-columns: repeat(4, 1fr);
  }
}
/* BannerFixed */
.banner-fixed {
  position: relative;
  height: 100vh;
  background-image: url('https://i.pinimg.com/474x/08/6b/98/086b9856809de3bbd5c397306b3d4d6b.jpg');
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner-content {
  text-align: center;
  color: white;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  padding: 30px;
  border-radius: 10px;
  max-width: 600px;
  width: 100%;
}

.banner-title {
  font-size: 2.5rem;
  margin-bottom: 15px;
  font-weight: bold;
}

.banner-description {
  font-size: 1.2rem;
  margin-bottom: 20px;
}


/* Gallery */
.gallery {
  text-align: center;
  padding: 30px;
}

.gallery h1 {
  font-size: 2.5rem;
  margin-bottom: 15px;
}

.gallery p {
  font-size: 1.2rem;
  margin-bottom: 30px;
}

.gallery-images {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.gallery-item {
  position: relative;
  overflow: hidden;
}

.gallery-item img {
  width: 100%;
  height: 200px; /* Ensure all images are the same height */
  object-fit: cover; /* Ensure images maintain their aspect ratio */
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.gallery-item img:hover {
  transform: scale(1.05);
}

.gallery-item h3 {
  font-size: 1.2rem;
  margin-top: 10px;
}

/* Modal Styles */
.modal-content {
  position: relative;
  max-width: 80%;
  margin: auto;
  padding: 20px;
  text-align: center;
}

.modal-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-gallery {
  position: relative;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
}

.modal-image-container img {
  max-width: 100%;
  border-radius: 8px;
}

.modal-navigation {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

/* Apply the class to buttons instead of direct styling */
.button-style {
  background-color: #28AE93;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.button-style:hover {
  background-color: #218f77;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #ff4b4b;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 50%;
  cursor: pointer;
}

.close-button:hover {
  background-color: #d43f3f;
}
/* Testimonial */
.testimonial-section {
  text-align: center;
  padding: 50px 20px;
  max-width: 800px;
  margin: auto;
}

.testimonial-section h1 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.testimonial-card {
  background: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.testimonial-text {
  font-size: 1.1rem;
  font-style: italic;
  margin-bottom: 15px;
}

.testimonial-author {
  font-weight: bold;
  font-size: 1rem;
  color: #333;
}

.testimonial-role {
  font-size: 0.9rem;
  color: #777;
}

.slick-dots li button:before {
  color: #28AE93 !important; /* Active dot color */
}

.slick-dots li.slick-active button:before {
  color: #218f77 !important; /* Change dot color when active */
}
/* ProgressBarr */
.progress-content {
  padding: 50px 0;
  text-align: center;
  background-color: #f9f9f9;
}

.progress-bar {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
}

.progress-item {
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: relative;
}

.progress-item h3 {
  font-size: 36px;
  color: #333;
  margin-bottom: 10px;
  font-weight: bold;
}

.progress-item span {
  font-size: 16px;
  color: #777;
}

.progress {
  width: 100%;
  height: 10px;
  background-color: #e0e0e0;
  border-radius: 5px;
  margin-top: 20px;
  overflow: hidden;
  position: relative;
}

.progress.bg1 {
  background-color: #4caf50;
}

.progress.bg2 {
  background-color: #2196f3;
}

.progress.bg3 {
  background-color: #ff9800;
}

.progress.animate {
  animation: progressAnimation 2s ease-out forwards;
}

@keyframes progressAnimation {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .progress-bar {
    grid-template-columns: 1fr;
  }
}
/* History */
.history-section {
  position: relative;
  padding: 50px;
  background: #f4f4f4;
}

.history-background {
  background-image: url('https://i.pinimg.com/736x/2a/8f/53/2a8f538444785812ee864db6a0620cf2.jpg'); /* Replace with your background image URL */
  background-size: cover;
  background-position: center;
  padding: 100px 0;
  text-align: center;
  color: white;
}

.history-background h1 {
  font-size: 3rem;
  margin-bottom: 10px;
}

.history-background p {
  font-size: 1.5rem;
  margin-bottom: 50px;
}

.history-card {
  background: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.history-card h3 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 10px;
}

.history-card h4 {
  font-size: 1.5rem;
  color: #555;
  margin-bottom: 15px;
}

.history-card p {
  font-size: 1rem;
  color: #777;
}

.slick-dots {
  bottom: 10px !important;
}

.slick-prev,
.slick-next {
  font-size: 20px;
  color: #fff;
}

.slick-prev {
  left: -40px;
}

.slick-next {
  right: -40px;
}
/* BlogHome */
.blog-home {
  padding: 40px 20px;
  background-color: #f9f9f9;
  text-align: center;
}

.blog-home h1 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 20px;
}

.blog-home h2 {
  font-size: 1.8rem;
  color: #555;
  margin-bottom: 40px;
}

.blog-posts {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 20px;
  justify-items: center;
}

.blog-post {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  max-width: 350px;
  text-align: left;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.blog-post:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
}

.blog-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-bottom: 2px solid #f1f1f1;
}

.blog-post h3 {
  font-size: 1.6rem;
  color: #333;
  padding: 20px;
  margin: 0;
}

.post-date {
  font-size: 1rem;
  color: #888;
  padding: 0 20px 20px;
}

/* Categories */
.categories{
  margin-top: 7rem;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2rem;
}
.categories-details{
  background-color: var(--clr-primary-4);
  padding: 0.5rem;
  border-radius: var(--radius);
  border: 1px solid var(--clr-black);
  text-align: center;
}
.categories-img img{
  width:300px;
height: 300px;
  object-fit: cover;
}
.details-link-categories {
  display: flex;  
  justify-content: center;                   
  align-items: center;               
  background-color: var(--clr-primary-8); 
  padding: 0.5rem 1rem;              
  border-radius: 4px;                
  gap: 0.5rem;                       
}

.btn-categories {
  background-color: transparent;    
  border: 2px solid var(--clr-white); 
  color: var(--clr-black);           
  font-size: 1.2rem;                 
  padding: 0.5rem 1rem;   
  text-transform: uppercase;           
  cursor: pointer;                   
  transition: background-color 0.3s; 
}

.btn-categories:hover {
  background-color: var(--clr-primary-1); 
  color: var(--clr-white);             
}

span {
  display: flex;                        
  justify-content: center;               
  align-items: center;                  
  color: var(--clr-black);              
  font-size: 1.5rem;                     
}

/* Welcome */
.welcome{
  position: relative;
}
.welcome-img img{
 width: 100%;
 height: 600px;
 object-fit: cover;
}
.welcome-details h3{
  font-weight: 700;
  margin-top: 1rem;
}
.welcome-details p{
  font-size: 1.5rem;
}
.welcome-last-details{
  margin: 1rem;
}
.welcome-last-details p{
  font-size: 1.5rem;
}
.welcome-bg2{
  display: none;
}
@media (min-width:992px) {
  .welcome-container{
  display: grid;
  grid-template-columns: repeat(2,1fr);
align-items: center;
  }
  .welcome-bg2{
    display: block;
    position: absolute;
    top: -12%;
    right: 5rem;
  }
  .welcome-bg2 img{
   width: 300px;
   border-radius: 100%;
  }
  .welcome-bg1{
    display: block;
    position: absolute;
    top: 60%;
    left: 2rem;
  }
  .welcome-bg1 img{
   width: 300px;
   border-radius: 100%;
  }
  
}
/* BannerCommand */
.banner-commend{
  background-color: var(--clr-primary-10);
  text-align: center;
  padding: 1rem;
  color: var(--clr-primary-11);
}
.banner-commend-details h3{
  font-size: 2rem;
}
.banner-command-btn-container{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}
.first-btn{
  display: flex;
  gap: 1rem;
  background-color: var(--clr-primary-11);
  color: var(--clr-white);
  padding: 0.5rem;
  border-radius: var(--radius);
  font-size: 1.2rem;
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  transition: var(--transition);
  cursor: pointer;
}
.first-btn span{
  color: var(--clr-primary-1);
}
.first-btn:hover{
  border: 1px solid var(--clr-primary-11);
  background-color: var(--clr-white);
  color: var(--clr-primary-11);
}
.last-btn{
  display: flex;
  gap: 1rem;
  transition: var(--transition);
  cursor: pointer;
}
 a .last-btn{
  color: var(--clr-primary-11);
}
.last-btn:hover{
  color: var(--clr-primary-1);
}
/* Qualite */
.qualites-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 1rem;
}

.qualites-details {
  background-color: var(--clr-primary-2);
  width: 320px;
  display: flex; 
  flex-direction: column; 
  justify-content: space-between; 
  height: 450px; 
  padding: 1rem; 
  cursor: pointer;
  transition: var(--transition);
}

.qualites-details img {
  width:150px;
  height: 150px;
  margin: 1rem auto;
  display: block;
  border-radius: 100%;
 
}

.qualites-details h3, .qualites-details p {
  margin:0; 
  text-align: center; 
  flex-grow: 1; 
  
}

.qualites-details button {
  margin-top: auto; 
  align-self: center; 
  background-color: var(--clr-black);
  padding: 0.5rem;
  color: var(--clr-white);
  text-transform: uppercase;
  font-size: 1rem;
  letter-spacing: var(--spacing);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: var(--transition);
  box-shadow: var(--light-shadow);
}
.qualites-details button:hover {
  background-color: var(--clr-primary-2); 
  transform: translateY(-2px); 
  color: var(--clr-black);
}

.qualites-details button:active{
  transform: translateY(1px); 
}
.qualites-details:hover{
  background-color: var(--clr-primary-3);
}
/* Destination */
.destination{
  background: url("https://i.pinimg.com/236x/4b/24/7a/4b247ad0451920308b4ea6a663b15eb2.jpg") center/cover no-repeat;
  height:500px;
  max-width: 1600px;
  width: 90vw;
  margin: 0 auto;
  position: relative;
}
.destination::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black overlay with 50% opacity */
  z-index: 1; /* Ensures the overlay is on top of the background but below the content */
}
.destination-text{
  position: relative;
  max-width: 35rem;
  background-color: #C3E345;
  color: var(--clr-black);
  border-radius: 100%;
  padding: 1rem;
  z-index: 2;
}
.destination-text h3{
  color: var(--clr-primary-1);
  font-size: 2rem;
}
.destination-img img{
  width:100%;
  height: 500px;
  z-index: 2;
}
.container-destination{
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  text-align: center;
 
  
}
/* Blog */
.blog-container {
  padding: 2rem;
  text-align: center;
}

.blog-posts {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
}

.blog-post {
  background-color: #f9f9f9;
  padding: 1rem;
  border-radius: 10px;
  width: 300px;
  box-shadow: var(--light-shadow);
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 400px; 
}

.blog-post img {
  width: 100%;
  border-radius: 8px;
}

.blog-post h3 {
  font-size: 1.5rem;
  margin-top: 1rem;
  color: #333;
  min-height: 3rem; 
}

.blog-post p {
  margin-top: 0.5rem;
  color: #666;
  min-height: 4rem; 
}

.read-more-btn {
  margin-top: auto; 
  background-color: var(--clr-black);
  color: var(--clr-white);
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
}

.read-more-btn:hover {
  background-color: var(--clr-primary-1);
}
/* Footer */
.footer {
  background-color: #2c3e50;
  color: #fff;
  padding: 40px 20px;
  text-align: center;
}

.footer-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.footer-info, .footer-social, .footer-contacts, .footer-newsletter {
  margin-bottom: 20px;
}

.footer h3 {
  font-size: 18px;
  margin-bottom: 15px;
}

.footer ul {
  list-style: none;
  padding: 0;
}

.footer ul li {
  margin-bottom: 10px;
}

.footer a {
  color: #ecf0f1;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

.footer form {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer input {
  padding: 10px;
  margin-right: 10px;
  border: none;
  border-radius: 5px;
}

.footer button {
  padding: 10px 20px;
  background-color: #e74c3c;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: #fff;
}

.footer button:hover {
  background-color: #c0392b;
}

/* Responsive Layout */
@media (max-width: 768px) {
  .footer-container {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 480px) {
  .footer-container {
    grid-template-columns: 1fr;
  }

  .footer h3 {
    font-size: 16px;
  }

  .footer form {
    flex-direction: column;
    align-items: flex-start;
  }

  .footer input {
    margin-bottom: 10px;
    width: 100%;
  }

  .footer button {
    width: 100%;
  }
}

/* Add "G I E MAME DOUSSOU" Section */
.giemame-doussou {
  margin-top: 20px;
  font-size: 16px;
  color: #ecf0f1;
}

/* ButtonTop */
.scroll-to-top {
  position: fixed;
  bottom: 40px;
  right: 40px;
  background-color: var(--clr-white);
  color: var(--clr-primary-1);
  border: none;
  padding: 10px 15px;
  border-radius: 50%;
  border: solid 5px var(--clr-primary-1);
  cursor: pointer;
  font-size: 24px;
  z-index: 1000;
  transition: opacity 0.3s ease, transform 0.3s ease;
  opacity: 0.8;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fadeIn 0.5s forwards; 
}


.scroll-to-top:hover {
  opacity: 1;
  transform: scale(1.1); 
}
/* Hero */
.hero {
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  position: relative;
}

.hero-content {
  background-color: rgba(0, 0, 0, 0.5); 
  padding: 20px;
  border-radius: 10px;
}

.hero h1 {
  color: var(--clr-white); 
  font-size: 2.5rem; 
  text-align: center;
}
/* Animal */
.animal-details{
  margin-top: 5rem;
}
.products-animals{
  position: relative;
}
.prouduct-animal{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
}
.product-animal-details{
  background-color: var(--clr-white);
  box-shadow: var(--light-shadow);
  padding: 1rem;
  position: relative;
  overflow: hidden;
  height: 300px;
  cursor: pointer;
  transition: var(--transition);
}

.product-animal-details h3{
  font-size: 1.2rem;
  margin-top: 1rem;
  color: var(--clr-primary-1);
}

.product-animal-details:hover .details-link {
  bottom: 0; 
}

.product-animal-details img {
  width: 100%;
  height: 200px;
  display: block;
  transition: transform 0.3s ease; 
}
.product-animal-details:hover img {
  transform: scale(1.05); 
}
.details-link {
  position: absolute;
  bottom: -100%; 
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--clr-primary-1);
  padding: 0.5rem 1rem;
  gap: 1rem;
  border-radius: 4px;
  transition: bottom 0.3s ease; 
  cursor: pointer;
}
.details-link:hover{
  background-color: var(--clr-black);
  border: 1px solid var(--clr-primary-1);
  
}

.details-link span {
  display: flex;
  font-size: 1.5rem;
  color:var(--clr-white);
  align-items: center;
}

.btn-details {
  background: transparent;
  border: none;
  color: var(--clr-white);
  font-size: 1.2rem;
  cursor: pointer;
}

.filter-btn.active {
  color:var(--clr-primary-1);
}
.btn-categories-animal{
  display:flex;
  flex-direction: column;
 
}
.btn-categories-animal button{
   background-color: transparent;
 border: none;
 font-size: 1.2rem;
 margin: 0.5rem;
 text-transform:capitalize;
 letter-spacing: var(--spacing);
}
.header-bg-three{
  display: none;
}
@media (min-width:768px) {
  .product-categories-container{
  display:flex;
  justify-content: center;
  gap: 2rem;

}
.prouduct-animal{
  display: grid;
  grid-template-columns: repeat(3,1fr);
}
}
@media (min-width:992px) {
.prouduct-animal{
  display: grid;
  grid-template-columns: repeat(3,1fr);
}
.header-bg-three{
  display: block;
  position: absolute;
  top: 140%;
  right: 0;
}
}
/* Advirser */
.product-tile{
  margin-top:5rem ;
  text-align: center;
}
.product-tile h3{
  font-weight: bolder;
  font-size: 2rem;
  text-transform: capitalize;
}
.adiviser-container{
  display:flex;
  gap: 5rem;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 5rem;
}
.paragraph p{
  font-size: 1.2rem;
 
}
.adviser-products-img img{
  width: 300px;
  height: auto;
  object-fit:cover;
  border-radius: var(--radius);
  box-shadow: var(--light-shadow);
 
}
.advirserOne{
  background-color: var(--clr-primary-3);
  color: var(--clr-primary-1);
  height: 100%;
  box-shadow: var(--dark-shadow);
  padding: 0.5rem;
  
}

.filter-btn-adviser.active {
  color:var(--clr-white);
}
.btn-categories-adviser{
  display:flex;
  flex-direction: column;
 text-align: center;

}
.btn-categories-adviser button{
   background-color: transparent;
 border: none;
 font-size: 1.2rem;
 margin: 1rem auto;
 text-transform:capitalize;
 letter-spacing: var(--spacing);
 cursor: pointer;
}
.first-category {
  color:var(--clr-primary-1);
}

@media (min-width:992px) {
  .adiviser-container{
  display:flex;
  gap: 5rem;
  flex-wrap:nowrap;
  justify-content: space-between;
  
}
.advirserOne{
  flex: 1;
}
.adiviserTwo{
  flex: 3;
}
}
/* HeroVideo */
.hero-video {
  position: relative;
  height: auto; 
  overflow: hidden;
}

.hero-content {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white; 
  text-align: center;
  z-index: 1; 
}

h1 {
  font-size: 3rem; 
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); 
}
.about-us h3{
  margin-top: 5rem;
  text-align: center;
  font-size: 2rem;
}
.about-container{
  margin-top: 5rem;
}
.about-img img{
  width: 300px;
 height: auto;
  object-fit: cover;
  box-shadow: var(--light-shadow);
}
.about-details h3,.about-details h5{
font-size: 1.5rem;
}
.about-details h5{
  color: var(--clr-primary-1);
}
.about-details p{
  font-size: 1.5rem;
}
@media (min-width:768px) {
  .hero-content{
    display: block;
  }
  .about-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5rem;
  }
  
}
/* Pro Component Styles */
.pro {
  padding: 40px 20px;
  background-color:var(--clr-primary-10); 
  text-align: center; 
}

.pro h2 {
  font-size: 2.5rem;
  color:var(--clr-black); 
  margin-bottom: 20px; 
}

.pro-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); 
  gap: 20px; 
  padding: 0 20px; 
}

.pro-item {
  background-color:var(--clr-white); 
  border: 1px solid #ddd; 
  border-radius: 8px; 
  padding: 20px; 
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); 
  transition: transform 0.3s; 
}

.pro-item h3 {
  font-size: 1.5rem; 
  color:var(--clr-primary-1); 
  margin-bottom: 10px; 
}

.pro-item p {
  font-size: 1rem; 
  color: #555; 
}


.pro-item:hover {
  transform: translateY(-5px); 
}
.pro-description {
  font-size: 1.2rem; 
  color: #555; 
  margin-bottom: 30px; 
}

@media (max-width: 768px) {
  .pro {
    padding: 20px; 
  }

  .pro h2 {
    font-size: 2rem; 
  }

  .pro-item h3 {
    font-size: 1.25rem; 
  }
}
/* Faq */
.category {
  margin-bottom: 2rem;
}

.category h2 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 1rem;
}

.question {
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 1rem;
  padding: 1rem;
}

.question header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.question-btn {
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color:var(--clr-primary-1);
}
.question h5{
  font-size: 1.5rem;
}
.question p {
  margin-top: 1rem;
  color: #555;
  font-size: 1.5rem;
}
/* Contact */
/* Main Contact section styling */
.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 1.5rem;
  background-color: #f4f7fa;
}

.contact-details {
  max-width: 1200px;
  width: 100%;
}

.contact-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

@media (min-width: 768px) {
  .contact-container {
    flex-direction: row;
  }
}

/* Contact Form Styling */
.contact-form {
  background: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  flex: 1;
}

.contact-form .title h3 {
  font-size: 1.8rem;
  color: #333;
  font-weight: 600;
  text-align: center;
  margin-bottom: 1rem;
}

.contact-email {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.2rem;
  color: #007bff;
  margin-bottom: 1.5rem;
  text-align: center;
  justify-content: center;
}

.contact-email span {
  font-size: 1.8rem;
}

/* Input fields */
.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  background-color: #f9f9f9;
  transition: border-color 0.3s;
}

.contact-form input:focus,
.contact-form textarea:focus {
  border-color: #007bff;
  outline: none;
}

.contact-form textarea {
  height: 120px;
  resize: none;
}

.contact-form button {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.contact-form button:hover {
  background-color: #0056b3;
}

/* Map Styling */
.map-section {
  text-align: center;
  margin-top: 50px;
}

.map-section h1 {
  margin-bottom: 20px;
}

.map-contact {
  flex: 1;
  height: 400px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

@media (min-width: 768px) {
  .map-contact {
    height: auto;
  }
}
/* Shop */
.shop {
  text-align: center;
  padding: 20px;
}

.product-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.product-card {
  background-color: #f8f8f8;
  border-radius: 8px;
  padding: 20px;
  max-width: 200px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.product-card img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.product-card h3 {
  font-size: 18px;
  margin: 10px 0;
}

.product-card p {
  margin: 5px 0;
}

.product-card button {
  background-color: #e74c3c;
  border: none;
  padding: 10px 20px;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
}

.product-card button:hover {
  background-color: #c0392b;
}
.whatsapp-button {
  display: inline-block;
  background-color: #25d366; /* WhatsApp green */
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  margin-top: 10px;
}

.whatsapp-button:hover {
  background-color: #128c7e; /* Darker green when hovered */
}
/* Category Filter Section */
.category-filter {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: Arial, sans-serif;
}

/* Label for the category filter */
.category-filter label {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
  color: #333;
}

/* Dropdown select box */
.category-filter select {
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  transition: all 0.3s ease;
  width: 200px; /* Adjust width as necessary */
}

/* Dropdown select box hover effect */
.category-filter select:hover {
  border-color: #007bff;
  background-color: #f1f1f1;
}

/* Dropdown select box focus effect */
.category-filter select:focus {
  outline: none;
  border-color: #007bff;
  background-color: #fff;
}

/* Optional: For a cleaner look, add a little margin-top between the filter and the product list */
.product-list {
  margin-top: 20px;
}
#google_translate_element {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 9999;
}

@media (max-width: 768px) {
  #google_translate_element {
    top: 20px;
    right: 10px;
    font-size: 12px; /* Adjust font size for mobile */
  }
}

@media (max-width: 480px) {
  #google_translate_element {
    top: 15px;
    right: 10px;
    font-size: 10px; /* Further reduce font size for very small screens */
  }
}
